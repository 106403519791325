import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Loader from "../../components/Common/Loader";
import { routes } from "../../constants";
import { login } from "../../services/ApiService";
import Beer from "../../assets/images/footer-images/beer.png";
import Makati from "../../assets/images/footer-images/makati.png";
import { getIsLoggedIn, DappUI } from "@elrondnetwork/dapp-core";
import { getCityLogo, getLoginLogo } from "../../utils/utils";
import Sound from "../../assets/sounds/welcome.mp3";
import {
  isMobile,
  browserName,
  isAndroid,
  isIOS,
  isChrome,
  isSafari,
} from "react-device-detect";
import "./style.css";

import "./styles.css";
import Maiar from "../../assets/images/logo/maiar.png";

const LoginPage = () => {
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const { WalletConnectLoginButton } = DappUI;

  const isLoggedIn = getIsLoggedIn();
  useEffect(() => {
    if (isLoggedIn) {
      console.log("loggg", isLoggedIn);
      // localStorage.removeItem("persist:dapp-core-store");
      const user =
        localStorage.getItem("token") &&
        JSON.parse(localStorage.getItem("token"));
      if (user) {
        // window.location.href = routes.home;
        navigate(routes.home);
      }
    }
  }, [isLoggedIn]);

  const loginHandler = async (oldId, apiKey, wefftWalletAddress) => {
    try {
      setLoader(true);
      const response = await login({
        oldId,
        apiKey,
      });
      if (response) {
        setLoader(false);
        // console.log("Login Response => ", response.data);
        localStorage.setItem(
          "token",
          JSON.stringify(response.headers.authorization)
        );
        localStorage.setItem("wefftWalletAddress", wefftWalletAddress);
        localStorage.setItem("maiarAddress", wefftWalletAddress);
        localStorage.setItem("footerIndex", 0);
        localStorage.setItem("itemIndex", 0);
        localStorage.setItem("oldId", oldId);
        navigate(routes.home);
        // window.open(routes.home);
      }
    } catch (e) {
      setLoader(false);
      console.log(e);
    }
  };

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        minHeight: "100vh",
        flexDirection: "column",
      }}
    >
      {loader && <Loader />}
      <img
        src={getLoginLogo()}
        style={{
          width: 120,
          height: 120,
          objectFit: "cover",
          borderRadius: "50%",
          marginBottom: 20,
          marginTop: 20,
        }}
      />
      <img
        src={getCityLogo()}
        style={{
          width: 200,
          height: 200,
          objectFit: "cover",
          marginBottom: 30,
        }}
      />
      {/* <p
        style={{
          color: "#00acb8",
          marginTop: 0,
          fontFamily: "CanaroMedium",
          fontSize: 40,
        }}
      >
        B.E.E.R.
      </p> */}
      { Number(process.env.REACT_APP_FAKE_LOGIN) === 1 && <div
        style={{
          display: 'flex',
          flexDirection: 'row'
        }}
      >
        <button
          onClick={() =>
            loginHandler(
              "30485860-caf6-11e3-91ec-123139260fba",
              "Jy4CDgqpoISgOgl5soDtHn9gaFtTCGS1",
              "erd1e2rfnsg2dlqsaf5q7qmeweuxkt8rshe2jdnwce87rend4crax0vq0nrzu7"
            )
          }
          style={{
            padding: "0.5rem 0.75rem",
            width: 160,
            color: "red",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: 40,
            borderRadius: 25,
            backgroundColor: "white",
            fontSize: '14px'
          }}
        >
          <img
            src="https://bengga-spaces.sgp1.digitaloceanspaces.com/images/720/media_file_1667007374449.jpeg"
            style={{
              width: 24,
              height: 24,
              objectFit: "cover",
              borderRadius: "50%",
              marginRight: 10,
            }}
          />
          Player 1 (A)
        </button> 
        <button
          onClick={() =>
            loginHandler(
              "c0c0dfc0-eead-11ea-a413-0242ac11000c",
              "bzkJwI3w80D5H24qmcccS8SjWbM0tvTc",
              "erd1q9ufnylct3dj36t0fcn092g5zsadvrz74lzfa2y2dcxy7f6ygzlszae7sn"
            )
          }
          style={{
            padding: "0.5rem 0.75rem",
            width: 160,
            color: "blue",
            marginLeft: 20,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: 40,
            borderRadius: 25,
            backgroundColor: "white",
            fontSize: '14px'
          }}
        >
          <img
            src="https://bengga-spaces.sgp1.digitaloceanspaces.com/images/720/media_file_1667007501305.jpeg"
            style={{
              width: 24,
              height: 24,
              objectFit: "cover",
              borderRadius: "50%",
              marginRight: 10,
            }}
          />
          Player 2 (B)
        </button>
      </div> }
      {Number(process.env.REACT_APP_FAKE_LOGIN) === 1 && <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          marginTop: 10
        }}
      >
        <button
          onClick={() =>
            loginHandler(
              "5ec02ed0-7553-11ea-abd2-0242ac110008",
              "NYFQDQSvx4RPbrV9O9StkHY0ng0aaY2l",
              "erd1e2rfnsg2dlqsaf5q7qmeweuxkt8rshe2jdnwce87rend4crax0vq0dg63j"
            )
          }
          style={{
            padding: "0.5rem 0.75rem",
            width: 160,
            color: "purple",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: 40,
            borderRadius: 25,
            backgroundColor: "white",
            fontSize: '14px'
          }}
        >
          <img
            src="https://bengga-spaces.sgp1.digitaloceanspaces.com/images/720/media_file_1667007555833.jpeg"
            style={{
              width: 24,
              height: 24,
              objectFit: "cover",
              borderRadius: "50%",
              marginRight: 10,
            }}
          />
          Player 3 (C)
        </button>
        <button
          onClick={() =>
            loginHandler(
              "2d1d3420-d9c7-11eb-904b-86daee0498db",
              "LmFGiAnqW9qnvlDMSBsIqrSPIvNYWVhi",
              "erd1q9ufnylct3dj36t0fcn092g5zsadvrz74lzfa2y2dcxy7f6ygzlszvxlc6"
            )
          }
          style={{
            padding: "0.5rem 0.75rem",
            width: 160,
            color: "black",
            marginLeft: 20,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: 40,
            borderRadius: 25,
            backgroundColor: "white",
            fontSize: '14px'
          }}
        >
          <img
            src="https://bengga-spaces.sgp1.digitaloceanspaces.com/images/720/media_file_1667007595477.jpeg"
            style={{
              width: 24,
              height: 24,
              objectFit: "cover",
              borderRadius: "50%",
              marginRight: 10,
            }}
          />
          Player 4 (D)
        </button> 
      </div>}

      {Number(process.env.REACT_APP_FAKE_LOGIN) === 1 && <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          marginTop: 10
        }}
      >
        <button
          onClick={() =>
            loginHandler(
              "92d3ae60-725e-11ea-89b2-0242ac110008",
              "KgbJBRPxj5IvwYUy898DW4FUcmyrPatR",
              "erd1ht8kvgxmjxvy8wjhnzsenje0l26sn2pzwrfhmw3yjhyy5fk23cpqferrnl"
            )
          }
          style={{
            padding: "0.5rem 0.75rem",
            width: 160,
            color: "green",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: 40,
            borderRadius: 25,
            backgroundColor: "white",
            fontSize: '14px'
          }}
        >
          <img
            src="https://bengga-spaces.sgp1.digitaloceanspaces.com/images/720/media_file_1667007618699.jpeg"
            style={{
              width: 24,
              height: 24,
              objectFit: "cover",
              borderRadius: "50%",
              marginRight: 10,
            }}
          />
          Player 5 (A)
        </button>
        <button
          onClick={() =>
            loginHandler(
              "ec3d5250-87ba-11ea-9ac1-0242ac110008",
              "zmEXpN61E9yddyyc5gvtCfieCeFK8qX4",
              "erd1y797wexf3a38u7g64368yydgewpxheywwl3k5c3anta2297kruaq28qtpj"
            )
          }
          style={{
            padding: "0.5rem 0.75rem",
            width: 160,
            color: "brown",
            marginLeft: 20,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: 40,
            borderRadius: 25,
            backgroundColor: "white",
            fontSize: '14px'
          }}
        >
          <img
            src="https://bengga-spaces.sgp1.digitaloceanspaces.com/images/720/media_file_1667007644227.jpeg"
            style={{
              width: 24,
              height: 24,
              objectFit: "cover",
              borderRadius: "50%",
              marginRight: 10,
            }}
          />
          Player 6 (B)
        </button> 
      </div> }
      {/* <button
        onClick={() => {
          console.log("here");
          navigate(routes.unlock);
        }}
        style={{
          padding: "0.5rem 0.75rem",
          width: 200,
          color: "black",
          marginTop: 20,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: 40,
          borderRadius: 25,
          backgroundColor: "white",
        }}
      >
        Wallet
      </button> */}
      {/* <div style={{ marginTop: 20, marginBottom: 10 }}>
        <WalletConnectLoginButton
          callbackRoute={routes.maiar}
          loginButtonText={`Connect to Maiar`}
          logoutRoute={routes.maiar}
          buttonClassName="button_maiar"
        />
        <img
          src={Maiar}
          style={{
            width: 24,
            height: 24,
            objectFit: "cover",
            borderRadius: "50%",
            position: "relative",
            left: 35,
            bottom: 36,
          }}
        />
      </div> */}
      <p style={{ color: "#db3eb1", marginTop: 30 }}>
        {process.env.REACT_APP_VERSION_PREFIX} {process.env.REACT_APP_VERSION}
      </p>
      {/* <iframe src={Sound}
        frameborder='0'
        allow='autoplay; encrypted-media'
        style={{width: 0, height: 0}}
        title='video'
        playsInLine
      /> */}
      <video
        muted={false}
        playsInline
        preload="auto"
        src={Sound}
        loop
        autoPlay
        alt="icon"
        style={{ width: 0, height: 0 }}
      />
      {!isMobile && (
        <p
          style={{
            color: "white",
            fontSize: 13,
            marginTop: 5,
            fontFamily: "CanaroBook",
          }}
        >
          *Best viewed on mobile browser
        </p>
      )}
      {isMobile && isIOS && !isSafari && (
        <p
          style={{
            color: "white",
            fontSize: 13,
            marginTop: 5,
            fontFamily: "CanaroBook",
          }}
        >
          *Best viewed on Safari
        </p>
      )}
      {isMobile && isAndroid && !isChrome && (
        <p
          style={{
            color: "white",
            fontSize: 13,
            marginTop: 5,
            fontFamily: "CanaroBook",
          }}
        >
          *Best viewed on Chrome
        </p>
      )}
    </div>
  );
};

export default LoginPage;
