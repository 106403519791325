import { ids } from '../constants';
import { nftHelper } from '../helpers/nftHelper';
import QCoin from '../assets/images/header-images/qc/Bengga Coin.png';
import MCoin from '../assets/images/header-images/makati/Bengga Coin.png';
import QLogo from '../assets/images/logo/qc/main_logo.png';
import MLogo from '../assets/images/logo/makati/main_logo.png';
import QLogoWhite from '../assets/images/logo/qc/main_logo_white.png';
import MLogoWhite from '../assets/images/logo/makati/main_logo_white.png';
import QLoginLogo from '../assets/images/logo/qc/login_main_logo.png';
import MLoginLogo from '../assets/images/logo/makati/login_main_logo.png';
import QLoginImage from '../assets/images/footer-images/qc/city.png';
import MLoginImage from '../assets/images/footer-images/makati/city.png';
import QStoreImage from '../assets/images/header-images/qc/store.png'; 
import MStoreImage from '../assets/images/header-images/makati/store.png';
import QTicket from '../assets/images/header-images/qc/ticket-header.png';

export const formatNumberWithComma = (number: number, fractionDigits: number) => {
  // console.log(`###number=${number};`)
  if (typeof number !== 'undefined') {      
      var parts;
      try {
        parts = Number(number).toFixed(fractionDigits).toString().split(".");
      }
      catch {
        // console.log(`###error`)
        parts = number.toString().split(".");
      }
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      if (fractionDigits === 0) {
        // console.log(`###parts[0]=${parts[0]};`)
        return parts[0];
      }
      else if (parts.length > 1) {
        parts[1] = parts[1].substring(0, fractionDigits);
      }
      // console.log(`###parts.join(".")=${parts.join(".")};`)
      return parts.join(".");
    // }
  }
  else {
    // console.log(`###`)
    return '';
  }
}

export const msToShortenedTimeLeft = (ms_diff, expiredText: ?string) => {
  let expired = 'Expired';
  if (typeof expiredText !== undefined && expiredText) { expired = expiredText };
  if (isNaN(ms_diff)) {
    return expired;
  }

  if (ms_diff <= 0) {
    return expiredText;
  }
  else {
    let days = Math.floor(ms_diff / 1000 / 60 / (60 * 24));
    let hours = Math.floor(ms_diff / 1000 / 60 / 60);
    let minutes = Math.floor(ms_diff / 1000 / 60);
    let seconds = Math.floor(ms_diff / 1000);
    // let date_diff = new Date(ms_diff);
    if (days === 0) {
      if (hours === 0) {
        if (minutes === 0) {
          if (seconds === 0) {
            return expiredText;
          }
          else {
            const second = seconds > 1 ? 'Secs' : 'Sec';
            return `${seconds} ${second}`;
          }
        }
        else {
          const minute = minutes > 1 ? 'Mins' : 'Min';
          return `${minutes} ${minute}`;
        }
      }
      else {
        const hour = hours > 1 ? 'Hrs' : 'Hr';
        return `${hours} ${hour}`;
      }
    }
    else {
      const day = days > 1 ? 'Days' : 'Day';
      return `${days} ${day}`;
    }
  }
}

export const getTimeLeft = (datetime): number => {
  // if (isNaN(datetime)) {
  //   console.log(`here`)
  //   return 0;
  // }

  let time = new Date(datetime).getTime();
  let now = new Date().getTime();
  // console.log(`date=${datetime};time=${time};now=${now}`);

  var ms_diff;
  if (time < now) {
    ms_diff = 0;
  }
  else {
    ms_diff = time - now;
  }
  return ms_diff;
}

export const getCoinImage = () => {
  switch (Number(process.env.REACT_APP_CITY)) {
    case ids.cityQuezon:
      return QCoin;
    case ids.cityMakati:
      return MCoin;
    default:
  }
}

export const getLogoWhite = () => {
  switch (Number(process.env.REACT_APP_CITY)) {
    case ids.cityQuezon:
      return QLogoWhite;
    case ids.cityMakati:
      return MLogoWhite;
    default:
  }
}

export const getLogo = () => {
  switch (Number(process.env.REACT_APP_CITY)) {
    case ids.cityQuezon:
      return QLogo;
    case ids.cityMakati:
      return MLogo;
    default:
  }
}

export const getLoginLogo = () => {
  switch (Number(process.env.REACT_APP_CITY)) {
    case ids.cityQuezon:
      return QLoginLogo;
    case ids.cityMakati:
      return MLoginLogo;
    default:
  }
}

export const getCityLogo = () => {
  switch (Number(process.env.REACT_APP_CITY)) {
    case ids.cityQuezon:
      return QLoginImage;
    case ids.cityMakati:
      return MLoginImage;
    default:
  }
}

export const getStoreImage = () => {
  switch (Number(process.env.REACT_APP_CITY)) {
    case ids.cityQuezon:
      return QStoreImage;
    case ids.cityMakati:
      return MStoreImage;
    default:
  }
}

export const getTicketImage = () => {
  switch (Number(process.env.REACT_APP_CITY)) {
    case ids.cityQuezon:
      return QTicket;
    default:
  }
}