import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  mainHeaderContainer: {
    position: "fixed",
    maxWidth: "480px",
    width: "100%",
    height: '50px',
    top: "0px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: 'center',
    // zIndex: 4,
    zIndex: 1,
    backgroundColor: '#262626',
    paddingRight: "15px",
    paddingLeft: "15px",
    transform: "translateX(-50%)",
    left: "50%",
    [theme.breakpoints.down("xs")]: {
      maxWidth: "100%",
    },
  },
  ticketProfileContainer: {
    display: "flex",
    alignItems: "center",
    marginTop: "auto",
    marginBottom: "auto",
    width: 140,
    justifyContent: "center",
  },
  storeImage: {
    width: 38,
    height: 38,
    borderRadius: '50%',
    cursor: 'pointer',
  },
  storeContainer: {
    position: 'relative',
    marginTop: 'auto',
    marginBottom: 'auto',
    marginRight: 8
  },
}));
