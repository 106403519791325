import Famer from '../assets/images/footer-images/famer.png';
import FamerWhite from '../assets/images/footer-images/famer-white.png';
import Jackpot from '../assets/images/footer-images/jackpot.png';
import JackpotWhite from '../assets/images/footer-images/jackpot-white.png';
import Highlight from '../assets/images/footer-images/highlight.png';
import HighlightWhite from '../assets/images/footer-images/highlight-white.png';
import Tourney from '../assets/images/footer-images/tourney.png';
import TourneyWhite from '../assets/images/footer-images/tourney_white.png';
import Beer from '../assets/images/footer-images/beer.png';
import BeerWhite from '../assets/images/footer-images/beer-white.png';
import Topic from '../assets/images/footer-images/topic.png';
import TopicWhite from '../assets/images/footer-images/topic_white.png';
import Games from '../assets/images/footer-images/games.png';
import GamesWhite from '../assets/images/footer-images/games_white.png';

export const footerMenus = [
  {
    label: 'Games',
    icon: Games,
    iconWhite: GamesWhite,
  },
  // {
  //   label: 'Jackpot',
  //   icon: Jackpot,
  //   iconWhite: JackpotWhite,
  // },
  // {
  //   label: 'BEER',
  //   icon: Beer,
  //   iconWhite: BeerWhite,
  // },
  {
    label: 'Topics',
    icon: Topic,
    iconWhite: TopicWhite,
  },
  {
    label: 'Tourneys',
    icon: Tourney,
    iconWhite: TourneyWhite,
  },
];
